import { LitElement, html } from 'lit'
import { msg, str, updateWhenLocaleChanges } from '@lit/localize'
import Pusher from 'pusher-js'
import { setLocale } from '#js/components/lit-i18n'

export class WaitingRoom extends LitElement {
  static get properties () {
    return {
      startDate: { type: String },
      statusChannel: { type: String },
      verboseTimeLeft: { type: String, attribute: false, reflect: true },
      timeDiff: { type: Number, attribute: false, reflect: true }
    }
  }

  constructor () {
    super()
    setLocale(window.language)
    updateWhenLocaleChanges(this)
    Pusher.logToConsole = window.debug
  }

  firstUpdated () {
    this.startDate = Date.parse(this.startDate)
    setInterval(this.getRemainingTime.bind(this), 1 * 1000)

    // subscribe to a pusher channel to get notified when the talk starts
    this.subscribeToChannel(this.statusChannel, 'live')
      .then(() => {
        window.location.reload()
      })
  }

  subscribeToChannel (channelName, eventName) {
    const pusher = new Pusher(window.pusherChannelsKey, { cluster: 'eu' })
    const channel = pusher.subscribe(channelName)

    return new Promise((resolve, reject) => {
      channel.bind(eventName, resolve)
      channel.bind('pusher:subscription_error', function (status) {
        reject(new Error(`Failed to subscribe to channel: ${status}`))
      })
    })
  }

  render () {
    return html`
      <link rel="stylesheet" href="${window.styleFilePath}"/>
      <div style="text-align: center">
        <svg viewBox="0 0 600 300" style="height: 300px">
          <use href="${window.svgSprite}#waitingroom"
               style="color: var(--brand-color);"></use>
        </svg>
        <h2>
          ${this.verboseTimeLeft}
        </h2>
      </div>
    `
  }

  getRemainingTime () {
    this.timeDiff = this.startDate - Date.now()
    const minutes = Math.floor((this.timeDiff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((this.timeDiff % (1000 * 60)) / 1000)

    const secondsStr = seconds < 10 ? `0${seconds}` : seconds
    this.verboseTimeLeft = msg(str`Please wait here, the talk will start in ${minutes}:${secondsStr} minutes.`)
    if (this.timeDiff > 60 * 60 * 1000) {
      this.verboseTimeLeft = msg(str`Please come back later, the talk will start at ${new Date(this.startDate).toLocaleString(window.language, { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}.`)
    }
    if (this.timeDiff < 0) {
      this.verboseTimeLeft = msg('Please wait here, the talk will start soon.')
    }
  }
}

window.customElements.define('waiting-room', WaitingRoom)
